<template>
  <div>
    <!-- 发票概览 -->
    <div class="invoice">
      <div class="invoice-overview">
        <div class="person-header">
          <p>
            <strong>发票概览</strong>
          </p>
        </div>
        <div class="mb-40"></div>
        <div class="invoice-overview-border flex-sb">
          <div class="invoice-overview-border1">
            <div class="invoice-overview-border1-center">
              <span class="f-14 h-57">已开具发票</span>
              <div class="mb-20"></div>
              <p><strong class="f-36 mr-10">{{ indexBar.opened_count }}</strong><span class="f-14 h-57">张</span></p>
            </div>
          </div>
          <div class="invoice-overview-border2">
            <div class="invoice-overview-border2-center">
              <span class="f-14 h-57">发票申请中</span>
              <div class="mb-20"></div>
              <p><strong class="f-36 mr-10">{{ indexBar.opening_count }}</strong><span class="f-14 h-57">张</span></p>
              <div class="mb-20"></div>
              <el-button size="mini" class="invoice-overview-border2-button" round>审核期限3-5个工作日，请耐心等候</el-button>
            </div>
          </div>
          <div class="invoice-overview-border3">
            <div class="invoice-overview-border3-center">
              <span class="f-14 h-57">未开具发票</span>
              <div class="mb-20"></div>
              <p><strong class="f-36 mr-10">{{ indexBar.wait_count }}</strong><span class="f-14 h-57">张</span></p>
              <div class="mb-20"></div>
              <el-button type="primary" size="mini" @click="toApply" round>申请发票</el-button>
            </div>
          </div>
        </div>
        <div class="invoice-overview-border-font">
          <div class="mb-20"></div>
          <span class="r f-14">温馨提示:</span>
          <div class="mb-10"></div>
          <ul>
            <li>1.八爪灵工推荐您使用电子发票。 电子发票与纸质发票具有同等法律效力， 可在发票通过审核后24小时内下载！</li>
            <li>2.您的纸质发票申请将于15个工作日内完成审批及开具， 发票开具完成后3个工作日寄出。收到发票包裹后，如有问题请及时拒收。</li>
            <li>3.单张电子发票金额不能超过10万，单张纸质发票金额不能超过100万。 若您申请的开票金额超过限额，将在审核通过后拆分为多笔不超限额的发票记录， 经拆分的发票金额总和将等于您的申请开票金额。</li>
          </ul>
        </div>
      </div>
      <!-- 开票记录 -->
      <div class="mb-20"></div>
      <div class="invoice-record">
        <div class="person-header">
          <p><strong>开票记录</strong></p>
        </div>
        <div class="mb-20"></div>
        <!-- 搜索框部分 -->
        <div class="invoice-search">
          <div class="block1">
            <el-date-picker
                v-model="searchData.apply_at"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:414px" size="small"
                clear-icon
                clearable
                value-format="yyyy-MM-dd HH:mm:ss" >
            </el-date-picker>
          </div>
          <el-select v-model="searchData.status" clearable placeholder="发票状态" style="width:100px;" class="ml-20"
                     size="small">
            <el-option v-for="item in invoiceOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="searchData.sn" placeholder="项目编号" style="width:200px;margin-left:20px"
                    size="small" clearable></el-input>
          <el-button class="invoiceButton" type="primary" icon="el-icon-search" size="small" @click="toSearch">搜索
          </el-button>
        </div>
        <div class="mb-20"></div>
        <!-- 发票记录表格 -->
        <el-table
            ref="multipleTable" border stripe
            :data="invoiceData"
            style="min-height: 250px"
            class="invoice-tabel"
            empty-text="您还没有开票记录！"
            :highlight-current-row="true"
            :header-cell-style="tableHeaderCellStyle"
            :cell-style="tableCellStyle">
          <el-table-column label="发票抬头" width="184px" prop="">
            <template slot-scope="scope">
              {{scope.row.certification ? scope.row.certification.company_name : '没有主体'}}
            </template>
          </el-table-column>
          <el-table-column prop="apply_at" label="申请开票时间" width="159px">
          </el-table-column>
          <el-table-column prop="amount" label="发票金额" width="108px">
            <template slot-scope="scope">
              ￥{{ scope.row.amount,'元'|moneyFormat }}
            </template>
          </el-table-column>
          <el-table-column prop="type_name" label="发票类型" width="115px">
          </el-table-column>
          <el-table-column prop="style_name" label="发票介质" width="88px">
          </el-table-column>
          <el-table-column label="状态" width="84px">
            <template slot-scope="{row}">
              <span v-if="row.status==1">{{ row.status_name }}</span>
              <span v-if="row.status==2" class="r">{{ row.status_name }}</span>
              <span v-if="row.status==3" class="g">{{ row.status_name }}</span>
              <span v-if="row.status==4" style="color: #9a6e3a" >{{ row.status_name }}</span>
              <span v-if="row.status==5" style="color: lightseagreen" >{{ row.status_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="handle" label="操作" width="120px">
            <template slot-scope="{ $index, row }">
              <el-button type="text" size="mini" @click="toInfo($index, row)">
                查看详情
              </el-button>
              <el-button type="text" size="mini" @click="toAgain(row)" v-if="row.status==2">
                重试
              </el-button>
              <el-button type="text" size="mini" @click="toDownload(row)" v-if="row.status==9&&row.style==1&&row.file_id!=0">
                下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="mb-40"></div>
        <div class="block">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pages.page"
              :page-sizes="[3, 5, 10]"
              :page-size="pages.page_size"
              layout="prev, pager, next,->,total,jumper"
              :total="pages.total"
              background
              :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getIndex, getList} from '@/api/invoice'

export default {
  data() {
    return {
      invoiceData: [],
      value1: "", //日期
      // 1=> '申请', 2 => '审核失败', 3 => '审核通过', 4 => '已开具', 5 => '已邮寄'
      invoiceOptions: [{
        value: '1',
        label: '未开具'
      }, {
        value: '2',
        label: '审核失败'
      }, {
        value: '3',
        label: '开具中'
      }, {
        value: '4',
        label: '已开具'
      }, {
        value: '5',
        label: '已邮寄'
      }],
      invoiceSelect: '',
      invoiceInput: '',

      // ***********************
      indexBar: {
        opened_count: 0,
        opening_count: 0,
        wait_count: 0,
      },
      pageParmas: {
        page: 1,
        page_size: 10
      },
      pages: {
        page: 1,
        page_size: 10,
        total: 0
      },
      tableHeaderCellStyle: {
        'text-align': 'center',
        'height': '36px',
        'color': '#000',
        'background': '#f4f4f4',
        'fon-size': '14px'
      },
      tableCellStyle: {
        'text-align': 'center',
        'height': '50px',
        'color': '#474747',
        'font-size': '12px',
      },
      searchData: {
        status: '',
        apply_at: '',
        sn: ''
      }
      // ***********************
    }
  },
  mounted() {
    this.loadIndex()
    this.loadList(this.pageParmas)
  },
  methods: {
    async loadList(data) {
      const res = await getList(data)
      if (res.statusCode == 200) {
        this.invoiceData = res.data.list
        this.pages.page = Number(res.data.page.page)
        this.pages.page_size = Number(res.data.page.page_size)
        this.pages.total = Number(res.data.page.total)
      }
    },
    async loadIndex() {
      const res = await getIndex()
      if (res.statusCode == 200) {
        this.indexBar = res.data
      }
    },
    handleSizeChange(val) {
      this.pageParmas.page_size = val
      this.loadList(this.pageParmas)
    },
    handleCurrentChange(val) {
      this.pageParmas.page = val
      this.loadList(this.pageParmas)
    },
    toInfo(index, row) {
      this.$router.push( { path: '/home/hirer/account/invoice/info', query: {id:row.id} } )
    },
    toDownload(row) {
      window.open(row.file)
    },
    toApply() {
      this.$router.push('/home/hirer/account/invoice/apply')
    },
    toSearch() {
      this.loadList(this.searchData)
    },
    toAgain( row ){
      this.$router.push('/home/hirer/account/invoice/apply')
    }
  },
}
</script>

<style lang="less" scoped>
.invoice-overview {
  width: 940px;
  background-color: #fff;
  padding-bottom: 20px;

  .invoice-overview-border {
    width: 860px;
    margin: 0 auto;
    padding-bottom: 40px;
    border-bottom: 1px solid #b5b5b5;

    .invoice-overview-border1,
    .invoice-overview-border2,
    .invoice-overview-border3 {
      width: 280px;
      height: 114px;
      text-align: center;
    }

    .invoice-overview-border1-center {
      p {
        width: 280px;

        .invoice-overview-border1-center {
          width: 72px;
          height: 70px;
          margin: 0 auto;
        }
      }

      .invoice-overview-border2 {
        margin: 0 auto;
        text-align: center;

        .invoice-overview-border2-button {
          width: 160px;
          line-height: 12px;
        }
      }
    }

    .invoice-overview-border3 {
      text-align: center;

      .invoice-overview-border3-center {
        width: 100px;
        margin: 0 auto;

        button {
          margin: 0 auto;
          width: 100px;
          background: #00A2E6;
          border-radius: 12px;
        }
      }
    }
  }

  .invoice-overview-border-font {
    width: 860px;
    margin: 0 auto;

    li {
      font-size: 12px;
      color: #575757;
      line-height: 28px;
    }
  }

  .invoice-overview-border-font {
    width: 860px;
    margin: 0 auto;
    padding-bottom: 40px;

    li {
      font-size: 12px;
      color: #575757;
      line-height: 28px;
    }
  }
}

/* 开票记录 */
.invoice-record {
  background-color: #fff;

  .invoice-search {
    width: 860px;
    margin: 0 auto;
    height: 32px;
    display: flex;
    align-items: center;

    .invoiceButton {
      width: 80px;
      background-color: #00A2E6;
      margin-left: 25px;
    }
  }

  /deep/ .invoice-tabel {
    width: 860px;
    margin: 0 auto;
  }

  .block {
    padding-bottom: 20px;
    background-color: #fff;
    width: 430px;
    margin: 0 auto;
  }
}

/* 正在开票的颜色 */
.blue {
  color: #00A2E6;
}

</style>
